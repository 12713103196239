/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  background: rgb(241, 241, 241);
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MaterialIcons/materialIcon.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MaterialIcons/MaterialIconsOutlined.woff2) format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.padding {
  padding: 20px;
  max-width: 100vw;
  width: calc(100% - 20px);
  margin: auto;
}

.file-drop-zone {
  border: 3px dashed var(--primary-color);
  border-radius: 4px;
  text-align: center;
  padding: 100px 0 0 0;
  min-height: 200px;
}
.file-drop-zone .ngx-file-drop__content {
  color: #000000 !important;
  height: 100% !important;
}

.dropper {
  height: 100%;
}

#add-icon {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.file-selector {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.invisible {
  display: none;
}